import React from "react";
import { observer } from "mobx-react";

import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Markdown } from "../../framework/components/Markdown";
import { FadeIn } from "../../framework/components/styled/FadeIn";
import { DefaultLink } from "../../framework/components/Link";
import { Device, I18n, Location } from "../../framework/store";

import { List } from "./List";
import { Rules } from "../store/rules";

// -----------------------------------------------------------------------------
// Styled components
// -----------------------------------------------------------------------------
const ContentContainer = styled.section`
  display: flex;
  height: 100%;
`;

// -----------------------------------------------------------------------------

const RulesContainer = styled.article`
  color: ${({ theme }) => theme.content.color};
  background: ${({ theme }) => theme.content.background};

  flex-grow: 1;

  padding: 0.8rem 1.25rem;
  hyphens: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
`;

// -----------------------------------------------------------------------------

const Title = styled.h1`
  font-family: "Amatic SC", cursive;
  font-weight: 700;
  font-size: 2.3rem;
  flex-grow: 2;
  margin: 0 0 0.25rem 0;
  hyphens: none;
`;

// -----------------------------------------------------------------------------

const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  margin-bottom: 0.75rem;
  padding-bottom: 0.4rem;
  border-bottom: 1px solid ${({ theme }) => theme.list.border};
`;

// -----------------------------------------------------------------------------

const Tokens = styled.div`
  min-width: 3rem;
  text-align: right;
  span {
    margin-left: 0.5rem;
  }
`;

// -----------------------------------------------------------------------------

const RulebookLink = styled(DefaultLink)`
  margin-left: 1rem;
  text-align: right;

  color: ${({ theme }) => theme.content.color};

  /* SEO Text */
  span {
    width: 0;
    height: 0;
    overflow: hidden;
    float: right;
  }
`;

// -----------------------------------------------------------------------------

const GameName = styled.div`
  flex-grow: 2;
`;

// -----------------------------------------------------------------------------

const Button = styled.button`
  font-size: 1.5rem;
  border: none;
  margin: 0.75rem 0;
  padding: 0;
  float: right;
  background-color: transparent;
  cursor: pointer;

  &:focus {
    outline: 0;
  }

  transition: transform 0.2s ease-in;

  &:hover {
    transform: scale(1.2);
  }
`;

// -----------------------------------------------------------------------------
// Rendering components
// -----------------------------------------------------------------------------
const GoBackButton = observer(() => {
  if (Device.isDesktop) {
    return null;
  }

  return (
    <Button
      aria-label={I18n.translations.back}
      onClick={() => Location.goBack()}
    >
      <FontAwesomeIcon icon="arrow-left" />
    </Button>
  );
});

// -----------------------------------------------------------------------------

@observer
export class RulesComponent extends React.Component {
  render() {
    const content = Rules.content || {};
    return (
      <RulesContainer>
        <FadeIn>
          {content.name && <Title>{content.name}</Title>}
          <Info>
            <GameName>{content.game}</GameName>
            {content.tokens !== undefined && (
              <Tokens>
                <FontAwesomeIcon icon="clone" />
                <span>{content.tokens}</span>
              </Tokens>
            )}
            {content.pdf && (
              <RulebookLink to={content.pdf} title={I18n.translations.rulebook}>
                <FontAwesomeIcon icon="file-pdf" />
              </RulebookLink>
            )}
          </Info>
          <Markdown text={content.rule} />
          <GoBackButton />
        </FadeIn>
      </RulesContainer>
    );
  }
}

// -----------------------------------------------------------------------------

@observer
export class Content extends React.Component {
  render() {
    // Tablet or Desktop, show sidebar
    if (Device.isDesktop) {
      return (
        <ContentContainer>
          <List />
          <RulesComponent />
        </ContentContainer>
      );
    } else {
      return Rules.content ? <RulesComponent /> : <List />;
    }
  }
}
