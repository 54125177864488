import { observable, action } from "mobx";
import debounce from "lodash-es/debounce";

class DeviceState {
  @observable
  isDesktop = false;

  @observable
  height = 0;

  // ---------------------------------------------------------------------------

  constructor() {
    this.mql = window.matchMedia("screen and (min-width: 768px)");
    this.mql.addListener(this.handleBreakpoint);
    this.handleBreakpoint(this.mql);

    // debounce at least 500ms due to iOS safari bug
    // https://openradar.appspot.com/radar?id=5040881597939712
    window.onresize = debounce(this.onResize, 500);
    this.height = window.innerHeight;
  }

  // ---------------------------------------------------------------------------

  @action
  onResize = () => {
    this.height = window.innerHeight;
  };

  // ---------------------------------------------------------------------------

  @action
  handleBreakpoint = event => {
    this.isDesktop = event.matches;
  };
}

export const Device = new DeviceState();
