const easeInOutQuad = (time, start, change, duration) => {
  time /= duration / 2;
  if (time < 1) {
    return (change / 2) * time * time + start;
  }
  time--;
  return (-change / 2) * (time * (time - 2) - 1) + start;
};

export const scrollElement = (element, to, duration) => {
  const start = element.scrollTop;
  const change = to - start;
  const increment = 2;
  let currentTime = duration % increment;

  const animateScroll = () => {
    currentTime += increment;
    element.scrollTop = easeInOutQuad(currentTime, start, change, duration);

    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    }
  };
  animateScroll();
};

export const scrollPage = (to, duration) =>
  scrollElement(
    document.scrollingElement || document.documentElement,
    to,
    duration
  );
