import { observable, action } from "mobx";
import _includes from "lodash-es/includes";
import { Location } from "./location";

class LanguageState {
  @observable
  current = "en";

  @observable
  supportedLanguages = [ "en", "de" ];

  // ---------------------------------------------------------------------------

  constructor() {
    this.detectLanguage();
  }

  // ---------------------------------------------------------------------------

  @action
  detectLanguage() {
    this.current = Location.language || (
      (navigator.languages && navigator.languages[0]) ||
      navigator.language ||
      navigator.userLanguage
    ).substr(0, 2);
  }

  // ---------------------------------------------------------------------------

  @action
  changeLanguage(code) {
    if(_includes(this.supportedLanguages, code)) {
      this.current = code;
    }
  }
}

export const Language = new LanguageState();
