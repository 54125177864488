import React from "react";
import styled from "styled-components";
import { autorun } from "mobx";

import { Location } from "../store";

// -----------------------------------------------------------------------------
// Rendering component
// -----------------------------------------------------------------------------
export class Link extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false
    };
  }

  componentDidMount() {
    this.cancelAutorun = autorun(this.updateActive);
  }

  componentWillUnmount() {
    this.cancelAutorun();
  }

  // Automatically update the active state of the link as
  // soon as the LocationStore changes
  updateActive = () => {
    const active = Location.isActive(this.props.to, this.props.prefixMatch);
    if (this.state.active !== active) {
      this.setState({ active });
    }
  };

  get className() {
    return this.state.active
      ? `${this.props.className} active`
      : this.props.className;
  }

  get target() {
    const url = this.props.to;
    return url.startsWith("http")
      ? { href: url, target: "_blank", rel: "noopener" }
      : { href: Location.createHref(url) };
  }

  handleClick = (e) => {
    if(!this.target.target) {
      e.preventDefault();
      Location.push(this.target.href);
    }
  }

  render() {
    return (
      <a
        {...this.target}
        onClick={this.handleClick}
        className={this.className}
        title={this.props.title}
      >
        {this.props.children}
      </a>
    );
  }
}

// -----------------------------------------------------------------------------
// Styled components
// -----------------------------------------------------------------------------
export const DefaultLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.link.color};

  @media screen and (min-width: 768px) {
    &:hover {
      color: ${({ theme }) => theme.link.hover};
    }
  }

  transition: color 0.2s ease-in;

  &.active,
  &:active {
    color: ${({ theme }) => theme.link.hover};
  }
`;
