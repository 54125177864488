import React from "react";
import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react";

import { StyledListEntry, StyledList } from "./List";
import { Rules } from "../store/rules";
import { I18n } from "../../framework/store";
import { Overlay } from "../../framework/components/Overlay";

// -----------------------------------------------------------------------------

const Options = styled(StyledList)`
  flex-grow: 2;
  margin: 0;
  list-style-type: none;
  width: 100%;

  overflow-y: auto;
`;

// -----------------------------------------------------------------------------

const Option = styled(StyledListEntry)`
  padding: 1rem;
  margin: 0;

  cursor: pointer;

  display: flex;
  align-items: center;

  &:focus {
    outline: 0;
  }

  > div {
    width: 1rem;
    height: 1rem;
    margin-right: 1rem;
    border: 2px solid;
    border-radius: 5px;
    padding: 4px;
    text-align: center;
  }

  > span {
    flex-grow: 2;
  }
`;

// -----------------------------------------------------------------------------
// Render Component
// -----------------------------------------------------------------------------
@observer
class SettingsEntry extends React.Component {
  render() {
    const game = this.props.game;
    return (
      <Option onClick={() => (game.filtered = !game.filtered)}>
        <div>{game.filtered === false && <FontAwesomeIcon icon="check" />}</div>
        <span>{game.name}</span>
      </Option>
    );
  }
}

// -----------------------------------------------------------------------------

@observer
export class Settings extends React.Component {
  render() {
    return (
      <Overlay
        title={I18n.translations.settings}
        iconOpen="cog"
        iconClose="check"
        onClose={Rules.update}
        padding="0"
      >
        <Options>
          {Rules.gameList.map(e => (
            <SettingsEntry key={e.name} game={e} />
          ))}
        </Options>
      </Overlay>
    );
  }
}
