import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSTransition } from "react-transition-group";
import { observer } from "mobx-react";

import { Device, I18n } from "../store";

const maxOverlayHeight = 528;

// -----------------------------------------------------------------------------
// Styled components
// -----------------------------------------------------------------------------
const OverlayBackground = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #00000090;
  z-index: 999;

  &.fade-enter {
    opacity: 0;

    > section {
      max-height: 0px;
    }
  }

  &.fade-enter-active {
    transition: opacity 400ms ease-in-out;
    opacity: 1;

    > section {
      max-height: ${maxOverlayHeight}px;
      transition: max-height 900ms ease-in-out;
      transition-delay: 100ms;
    }
  }

  &.fade-exit {
    opacity: 1;

    > section {
      max-height: ${maxOverlayHeight}px;
    }
  }

  &.fade-exit-active {
    opacity: 0;
    transition: opacity 400ms ease-in-out;
    transition-delay: 600ms;

    > section {
      max-height: 0px;
      transition: max-height 900ms ease-in-out;
    }
  }
`;

// -----------------------------------------------------------------------------

const OverlayContainer = styled.section`
  position: absolute;
  width: 320px;

  top: 0;
  transform: translateX(50%);
  right: 50%;
  overflow: hidden;

  color: ${({ theme }) => theme.content.color};
  background: ${({ theme }) => theme.content.background};
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.75);

  display: flex;
  flex-direction: column;
`;

// -----------------------------------------------------------------------------

const Title = styled.h1`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  width: 100%;
  z-index: 20;

  font-family: "Amatic SC", cursive;
  font-size: 1.5rem;
  font-weight: 400;
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.header.color};
  background: ${({ theme }) => theme.header.background};
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
`;

// -----------------------------------------------------------------------------

const OverlayContent = styled.div`
  font-size: 0.75rem;
  padding: ${props => props.padding || "1rem"};

  h2 {
    margin: 0;
  }

  p {
    margin-bottom: 1rem;
  }

  a {
    color: ${({ theme }) => theme.link.color};
    text-decoration: none;

    &:hover {
      color: ${({ theme }) => theme.link.hover};
    }
  }
`;

// -----------------------------------------------------------------------------

const OpenButton = styled.button`
  color: ${({ theme }) => theme.header.color};
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
  font-size: 1rem;

  cursor: pointer;

  &:focus {
    outline: 0;
  }

  transition: transform 0.2s ease-in;

  &:hover {
    transform: scale(1.2);
  }
`;

// -----------------------------------------------------------------------------

const CloseButton = styled.button`
  position: absolute;
  bottom: 6px;
  right: 0.75rem;

  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 3rem;
  min-width: 3rem;
  max-height: 3rem;
  max-width: 3rem;

  border-radius: 3rem;
  border-width: 0;

  color: ${({ theme }) => theme.footer.color};
  background: ${({ theme }) => theme.footer.background};

  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.25);

  transition: all 0.2s ease-in-out;
  &:hover {
    background: ${({ theme }) => theme.navigation.hover.background};

    > svg {
      transform: scale(1.15);
      color: ${({ theme }) => theme.navigation.hover.color};
    }
  }

  &:focus {
    outline: 0;
  }
`;

// -----------------------------------------------------------------------------
// Render Component
// -----------------------------------------------------------------------------
@observer
export class Overlay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      overlay: false
    };
  }

  openOverlay = () => {
    this.setState(() => ({ overlay: true }));
  };

  closeOverlay = () => {
    this.setState(() => ({ overlay: false }));
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  render() {
    const overlayHeight =
      Device.height < maxOverlayHeight
        ? Device.height
        : maxOverlayHeight;

    return <React.Fragment>
        <OpenButton aria-label={this.props.title} onClick={this.openOverlay}>
          <FontAwesomeIcon icon={this.props.iconOpen} />
        </OpenButton>

        <CSSTransition in={this.state.overlay} timeout={1200} classNames="fade" unmountOnExit>
          <OverlayBackground>
            <OverlayContainer style={{ height: overlayHeight }}>
              <Title>{this.props.title}</Title>
              <OverlayContent padding={this.props.padding}>{this.props.children}</OverlayContent>
              <CloseButton aria-label={I18n.translations.close} onClick={this.closeOverlay}>
                <FontAwesomeIcon icon={this.props.iconClose} />
              </CloseButton>
            </OverlayContainer>
          </OverlayBackground>
        </CSSTransition>
      </React.Fragment>;
  }
}

// -----------------------------------------------------------------------------

Overlay.propTypes = {
  iconClose: PropTypes.string.isRequired,
  iconOpen: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  title: PropTypes.string.isRequired,
}