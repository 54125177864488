import slugify from "../../framework/utils/slugify";
import { computed, autorun, observable, action } from "mobx";
import _find from "lodash-es/find";
import _includes from "lodash-es/includes";
import _mapValues from "lodash-es/mapValues";

import { Language } from "../../framework/store/language";
import { Location } from "../../framework/store/location";
import { Device } from "../../framework/store/device";
import { I18n } from "../../framework/store/i18n";

class RulesState {
  // ---------------------------------------------------------------------------
  // Member
  // ---------------------------------------------------------------------------
  @observable
  games = [];
  @observable
  categories = [];
  @observable
  lists = {};
  @observable
  slugs = {};

  // ---------------------------------------------------------------------------
  // Methods
  // ---------------------------------------------------------------------------
  constructor() {
    this.load();
    this.update();
    autorun(this.handleRouteChange);
  }

  // ---------------------------------------------------------------------------

  handleRouteChange = () => {
    // Update language data in case the url contains a different language
    if(Language.current !== Location.language) {
      Language.changeLanguage(Location.language);
      this.update();
    }

    const defaultCategory = this.categories[0].url;

    // On Desktop load the first entry when switching the category.
    // On mobile only load the first category on initial load but not
    // the underlying first entry.
    if (Device.isDesktop && !this.content) {
      if (!this.list || !this.lists[Location.path]) {
        Location.history.replace(this.lists[defaultCategory][0].url);
      } else {
        Location.history.replace(this.lists[Location.path][0].url);
      }
    } else if (!this.list) {
      Location.history.replace(defaultCategory);
    }
  };

  // ---------------------------------------------------------------------------

  @action
  update = () => {
    // Load language file
    const lang = Language.current === "de" ? "de" : "en";
    const ymlData = require(`../../assets/rules-${lang}.yml`);

    // Reset data
    this.lists = {};
    this.slugs = {};
    this.categories = [];
    I18n.translations = ymlData.translations;

    // Iterate all games and expansions and build datastructures
    Object.keys((ymlData || {}).rules).forEach(gameId => {
      // Add the game and check if it is filtered
      const game = this.addGame(ymlData.rules, gameId);
      if (game.filtered) {
        return;
      }

      // Lookup all game info types (races, powers, ...)
      Object.keys(game).forEach(type => {
        // skip meta keys for each game
        if (_includes(["name", "url", "id", "filtered"], type)) {
          return;
        }

        // Get and/or create category
        const category = this.addCategory(type);

        // Create list entries and slugs
        this.lists[category.url] = this.lists[category.url] || [];

        // push all entries into the data and map it also via slug
        Object.values(game[type]).forEach(entry => {
          entry.pdf = game.url;
          entry.game = game.name;
          entry.url =
            `${category.url}` +
            `/${slugify(game.name)}` +
            `/${slugify(entry.name)}`;

          this.lists[category.url].push(entry);
          this.slugs[entry.url] = entry;
        });
      });
    });

    // Sort all entries in each category (powers, races, ...)
    // and save the filtered state for each game in local storage
    this.sortListEntries();
    this.save();
    document.title = I18n.translations.title;
  };

  // ---------------------------------------------------------------------------

  sortListEntries = () => {
    // Sort all entries by name, use replace to do
    // inplace sort on mobx observable array
    Object.values(this.lists).forEach(list =>
      list.replace(list.slice().sort(this.sortByName))
    );
  };

  // ---------------------------------------------------------------------------

  sortByName = (a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    return nameA.localeCompare(nameB);
  };

  // ---------------------------------------------------------------------------

  addGame(dict, id) {
    const game = dict[id];

    this.games[id] = {
      // use filter settings from local storage
      filtered: this.games[id] ? this.games[id].filtered : false,
      ...game
    };
    return this.games[id];
  }

  // ---------------------------------------------------------------------------

  addCategory(type) {
    const slug = `/${Language.current}/${slugify(I18n.translations[type])}`;
    let category = _find(this.categories, el => el.url === slug);

    if (!category) {
      category = {
        url: slug,
        name: I18n.translations[type]
      };

      switch (type) {
        case "races":
          category.icon = "users";
          break;

        case "powers":
          category.icon = "fire";
          break;

        case "righteous_relics":
          category.icon = "broom";
          break;

        case "popular_places":
          category.icon = "heart";
          break;

        default:
          category.icon = "heart";
      }
      this.categories.push(category);
    }

    return category;
  }

  // ---------------------------------------------------------------------------

  save() {
    // only store filter state for a game
    localStorage.setItem(
      "games",
      JSON.stringify(_mapValues(this.games, e => ({ filtered: e.filtered })))
    );
  }

  // ---------------------------------------------------------------------------

  load() {
    this.games = JSON.parse(localStorage.getItem("games")) || {};
    if (Array.isArray(this.games)) {
      this.games = {};
    }
  }

  // ---------------------------------------------------------------------------
  // Getter
  // ---------------------------------------------------------------------------

  @computed
  get content() {
    return this.slugs[Location.path];
  }

  // ---------------------------------------------------------------------------

  @computed
  get list() {
    // get the category from the URL
    const match = Location.path.match(/\/\w{2}\/[^/]*/);
    return this.lists[match ? match[0] : undefined];
  }

  // ---------------------------------------------------------------------------

  @computed
  get gameList() {
    return Object.values(this.games).sort(this.sortByName);
  }
}

export const Rules = new RulesState();
