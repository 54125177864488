import React from "react";
import PropTypes from "prop-types";
import showdown from "showdown";

// -----------------------------------------------------------------------------
// Render Component
// -----------------------------------------------------------------------------
export class Markdown extends React.Component {
  parser = new showdown.Converter();

  createMarkup = markdown => ({
    __html: this.parser.makeHtml(markdown)
  });

  render() {
    return <div dangerouslySetInnerHTML={this.createMarkup(this.props.text)} />;
  }
}

// -----------------------------------------------------------------------------

Markdown.propTypes = {
  text: PropTypes.string
};
