import styled from "styled-components";

export const Spacer = styled.div`
  max-width: 40px;
  min-width: 40px;
  max-height: 40px;
  min-height: 40px;
  flex: 1 1 0;
  font-size: 1rem;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;