import React, { Component } from "react";
import { observer } from "mobx-react";
import styled, { createGlobalStyle, ThemeProvider } from "styled-components";

import theme from "./themes/green";

import FontStyle from  "../framework/fonts";

import { Navigation } from "../framework/components/styled/Navigation";
import { Header } from "../framework/components/Header";
import { Footer } from "../framework/components/Footer";
import { Overlay } from "../framework/components/Overlay";
import { Markdown } from "../framework/components/Markdown";
import { ServiceWorkerInfo } from "../framework/components/ServiceWorkerInfo";

import { Device, I18n, ServiceWorker } from "../framework/store";

// -----------------------------------------------------------------------------
// App specific imports
// -----------------------------------------------------------------------------
import logo from "../assets/logo.svg";
import { Content } from "./components/Content";
import { Settings } from "./components/Settings";
import { NavigationLinks } from "./components/NavigationLinks";

// -----------------------------------------------------------------------------
// Global application styles
// -----------------------------------------------------------------------------
const BodyStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Gudea';
    font-size: 21px;
  }
`;

// -----------------------------------------------------------------------------
// Styled components
// -----------------------------------------------------------------------------
const Grid = styled.div`
  display: flex;
  flex-flow: column;
`;

// -----------------------------------------------------------------------------
// Main application component
// -----------------------------------------------------------------------------
@observer
export class App extends Component {
  get height() {
    return {
      height: Device.height
    };
  }

  get headerProps() {
    return {
      logo,
      right: () => <Settings/>
    }
  }

  get footerProps() {
    return {
      right: () => (
        <Overlay title={I18n.translations.about.title} iconOpen="info-circle" iconClose="check">
          <Markdown text={I18n.translations.about.content} />
        </Overlay>)
    }
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Grid style={this.height}>
          <FontStyle />
          <BodyStyle />
          <Header {...this.headerProps} />
          <Navigation>
            <NavigationLinks />
          </Navigation>
          <Content />
          <Footer {...this.footerProps} />
          {ServiceWorker.hasUpdate && <ServiceWorkerInfo />}
        </Grid>
      </ThemeProvider>
    );
  }
}
