import React from "react";
import ReactDOM from "react-dom";

import { register } from "./framework/utils/registerServiceWorker";

// -----------------------------------------------------------------------------
// import assets
// -----------------------------------------------------------------------------
import "./assets/icons/android-chrome-192x192.png";
import "./assets/icons/android-chrome-512x512.png";
import "./assets/icons/apple-touch-icon.png";

import "./assets/splash/iPad.png";
import "./assets/splash/iPadLS.png";
import "./assets/splash/iPadPro1.png";
import "./assets/splash/iPadPro1LS.png";
import "./assets/splash/iPadPro2.png";
import "./assets/splash/iPadPro2LS.png";
import "./assets/splash/iPhone5.png";
import "./assets/splash/iPhone5LS.png";
import "./assets/splash/iPhone6.png";
import "./assets/splash/iPhone6LS.png";
import "./assets/splash/iPhonePlus.png";
import "./assets/splash/iPhonePlusLS.png";
import "./assets/splash/iPhoneX.png";
import "./assets/splash/iPhoneXLS.png";

// -----------------------------------------------------------------------------
// initialize app
// -----------------------------------------------------------------------------
import "./framework/store";
import { App } from "./app/App";

// -----------------------------------------------------------------------------
// Used Icons
// -----------------------------------------------------------------------------
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowLeft,
  faBroom,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faClone,
  faCog,
  faFilePdf,
  faFire,
  faHeart,
  faInfoCircle,
  faRedo,
  faUsers
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faArrowLeft,
  faBroom,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faClone,
  faCog,
  faFilePdf,
  faFire,
  faHeart,
  faInfoCircle,
  faRedo,
  faUsers
);

// -----------------------------------------------------------------------------
// initialize app
// -----------------------------------------------------------------------------
ReactDOM.render(<App />, document.getElementById("root"));
register();
