import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { Spacer } from "./styled/Spacer";

// -----------------------------------------------------------------------------
// Styled components
// -----------------------------------------------------------------------------
const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;

  color: ${({ theme }) => theme.header.color};
  background: ${({ theme }) => theme.header.background};
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);

  z-index: 2;

  flex: 0 0 40px;
`;

// -----------------------------------------------------------------------------

const HeaderImageContainer = styled.div`
  flex: 2 1 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// -----------------------------------------------------------------------------

const HeaderImage = styled.img`
  max-width: 150px;
  height: 30px;
`;

// -----------------------------------------------------------------------------
// Render Component
// -----------------------------------------------------------------------------
export const Header = (props) => (
  <HeaderContainer>
    <Spacer>
      {props.left && props.left()}
    </Spacer>

    <HeaderImageContainer>
      <HeaderImage src={props.logo} alt={document.title} />
    </HeaderImageContainer>

    <Spacer>
      {props.right && props.right()}
    </Spacer>
  </HeaderContainer>
)

// -----------------------------------------------------------------------------

Header.propTypes = {
  logo: PropTypes.string.isRequired,
  left: PropTypes.func,
  right: PropTypes.func
}