import React from "react";
import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FadeIn } from "./styled/FadeIn";
import { I18n } from "../store";

// -----------------------------------------------------------------------------
// Styled components
// -----------------------------------------------------------------------------
const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-60px, -60px);
  width: 120px;
  height: 120px;
  z-index: 999;

  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  color: ${({ theme }) => theme.footer.color};
  background: ${({ theme }) => theme.footer.background}d1;
  border-radius: 1rem;

  font-size: 0.9rem;

  svg {
    font-size: 3rem;
    margin-bottom: 0.25rem;
  }

  span {
    text-align: center;
  }

  cursor: pointer;
`;

// -----------------------------------------------------------------------------
// Render Component
// -----------------------------------------------------------------------------
export const ServiceWorkerInfo = () => (
  <FadeIn>
    <Container onClick={() => window.location.reload()}>
      <FontAwesomeIcon icon="redo" />
      <span>{I18n.translations.update}</span>
    </Container>
  </FadeIn>
);
