import { observable, action } from "mobx";

class I18nState {
  @observable
  translations = {};

  @action
  set(translations) {
    this.translations = translations;
  }
}

export const I18n = new I18nState();
