import { createGlobalStyle } from "styled-components";

import gudea_v7_latin_regular_woff2 from "./gudea-v7-latin-regular.woff2";
import gudea_v7_latin_regular_woff from "./gudea-v7-latin-regular.woff";
import gudea_v7_latin_italic_woff2 from "./gudea-v7-latin-italic.woff2";
import gudea_v7_latin_italic_woff from "./gudea-v7-latin-italic.woff";
import gudea_v7_latin_700_woff2 from "./gudea-v7-latin-700.woff2";
import gudea_v7_latin_700_woff from "./gudea-v7-latin-700.woff";
import amatic_sc_v11_latin_regular_woff2 from "./amatic-sc-v11-latin-regular.woff2";
import amatic_sc_v11_latin_regular_woff from "./amatic-sc-v11-latin-regular.woff";
import amatic_sc_v11_latin_700_woff2 from "./amatic-sc-v11-latin-700.woff2";
import amatic_sc_v11_latin_700_woff from "./amatic-sc-v11-latin-700.woff";

export default createGlobalStyle`
body {
  /* gudea-regular - latin */
  @font-face {
    font-display: auto;
    font-family: 'Gudea';
    font-style: normal;
    font-weight: 400;
    src: local('Gudea'),
        url(${gudea_v7_latin_regular_woff2}) format('woff2'),
        url(${gudea_v7_latin_regular_woff}) format('woff');
  }

  /* gudea-italic - latin */
  @font-face {
    font-display: auto;
    font-family: 'Gudea';
    font-style: italic;
    font-weight: 400;
    src: local('Gudea Italic'), local('Gudea-Italic'),
        url(${gudea_v7_latin_italic_woff2}) format('woff2'),
        url(${gudea_v7_latin_italic_woff}) format('woff');
  }
  /* gudea-700 - latin */
  @font-face {
    font-display: auto;
    font-family: 'Gudea';
    font-style: normal;
    font-weight: 700;
    src: local('Gudea Bold'), local('Gudea-Bold'),
        url(${gudea_v7_latin_700_woff2}) format('woff2'),
        url(${gudea_v7_latin_700_woff}) format('woff');
  }

  /* amatic-sc-regular - latin */
  @font-face {
    font-family: 'Amatic SC';
    font-style: normal;
    font-weight: 400;
    src: local('Amatic SC Regular'), local('AmaticSC-Regular'),
        url(${amatic_sc_v11_latin_regular_woff2}) format('woff2'),
        url(${amatic_sc_v11_latin_regular_woff}) format('woff');
  }

  /* amatic-sc-700 - latin */
  @font-face {
    font-family: 'Amatic SC';
    font-style: normal;
    font-weight: 700;
    src: local('Amatic SC Bold'), local('AmaticSC-Bold'),
        url(${amatic_sc_v11_latin_700_woff2}) format('woff2'),
        url(${amatic_sc_v11_latin_700_woff}) format('woff');
  }
}
`;
