import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react";
import styled from "styled-components";

import { Link } from "../../framework/components/Link";
import { Rules } from "../store/rules";

// -----------------------------------------------------------------------------
// Styled Components
// -----------------------------------------------------------------------------
const NavigationLink = styled(Link)`
  color: ${({ theme }) => theme.navigation.color};
  background: ${({ theme }) => theme.navigation.background};
  border-right: 1px solid ${({ theme }) => theme.navigation.color};

  &:last-child {
    border: none;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  flex: 1 1 0;

  text-decoration: none;
  padding: 0 0.25rem;

  span {
    font-size: 0.75rem;
    padding-top: 0.25rem;
    white-space: nowrap;
  }

  transition: all 0.2s ease-in-out;

  &:hover,
  &:active,
  &.active {
    color: ${({ theme }) => theme.navigation.hover.color};
    background: ${({ theme }) => theme.navigation.hover.background};
  }
`;

// -----------------------------------------------------------------------------
// Render Component
// -----------------------------------------------------------------------------
@observer
export class NavigationLinks extends React.Component {
  render() {
    return (
      <React.Fragment>
        {Rules.categories.map(entry => (
          <NavigationLink to={entry.url} key={entry.url} prefixMatch={true}>
            <FontAwesomeIcon icon={entry.icon} />
            {entry.name && <span>{entry.name}</span>}
          </NavigationLink>
        ))}
      </React.Fragment>
    );
  }
}
