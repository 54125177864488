export default {
  link: {
    color: "#679436",
    hover: "#FCA311"
  },

  header: {
    color: "#fff",
    background: "#272727"
  },

  navigation: {
    color: "#343a10",
    background: "#A5BE00",
    hover: {
      color: "#f4ffa6",
      background: "#679436"
    }
  },

  list: {
    color: "#333",
    game: "#777",
    background: "#fff",
    hover: {
      color: "#05668D",
      background: "#05668D1a"
    },
    border: "#efefef"
  },

  content: {
    color: "#333",
    background: "#fcfcfc"
  },

  footer: {
    color: "#fff",
    background: "#272727"
  }
};
