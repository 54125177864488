import React from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Link } from "../../framework/components/Link";
import { FadeIn } from "../../framework/components/styled/FadeIn";
import { scrollElement } from "../../framework/utils/scrollTo";

import { Rules } from "../store/rules";

// -----------------------------------------------------------------------------
// Styled components
// -----------------------------------------------------------------------------
const ListContainer = styled.aside`
  @media screen and (min-width: 768px) {
    min-width: 40%;
    max-width: 40%;
  }

  @media screen and (min-width: 1024px) {
    min-width: 30%;
    max-width: 30%;
  }

  background-color: ${({ theme }) => theme.list.background};

  @media screen and (min-width: 768px) {
    grid-area: sidebar;
    border-right: 1px solid ${({ theme }) => theme.list.border};
  }
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  height: 100%;
`;

// -----------------------------------------------------------------------------

export const StyledList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

// -----------------------------------------------------------------------------

export const StyledListEntry = styled.li`
  border-bottom: 1px solid ${({ theme }) => theme.list.border};

  &:last-child {
    border: none;
  }

  transition: all 0.2s ease-in-out;

  @media screen and (min-width: 768px) {
    &:hover {
      color: ${({ theme }) => theme.list.hover.color};
      background: ${({ theme }) => theme.list.hover.background};
    }
  }
`;

// -----------------------------------------------------------------------------

const StyledListLink = styled(FadeIn)`
  display: flex;
  padding: 0.75rem 1.25rem;
  text-decoration: none;

  color: ${({ theme }) => theme.list.color};

  &.active,
  &:active {
    color: ${({ theme }) => theme.list.hover.color};
    background: ${({ theme }) => theme.list.hover.background};
  }

  > span {
    flex-grow: 2;
    align-self: center;

    > div {
      font-size: 0.75rem;
      color: ${({ theme }) => theme.list.game};
    }
  }

  > svg {
    align-self: center;
  }
`.withComponent(Link);

// -----------------------------------------------------------------------------
// Rendering components
// -----------------------------------------------------------------------------

@observer
export class List extends React.Component {
  componentDidUpdate() {
    scrollElement(document.querySelector("aside"), 0, 100);
  }
  render() {
    return (
      <ListContainer>
        <StyledList>
          {(Rules.list || []).map(e => (
            <StyledListEntry key={e.url}>
              <StyledListLink to={e.url}>
                <span>
                  {e.name}
                  <div>{e.game}</div>
                </span>
                <FontAwesomeIcon icon="chevron-right" />
              </StyledListLink>
            </StyledListEntry>
          ))}
        </StyledList>
      </ListContainer>
    );
  }
}
