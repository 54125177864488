import styled from "styled-components";

// -----------------------------------------------------------------------------
// Styled components
// -----------------------------------------------------------------------------
export const Navigation = styled.nav`
  flex: 0 0 ${props => props.height || 60}px;

  z-index: 1;

  display: flex;
  justify-content: center;

  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);

  color: ${({ theme }) => theme.navigation.color};
  background: ${({ theme }) => theme.navigation.background};
`;
