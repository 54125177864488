import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { Spacer } from "./styled/Spacer";

// -----------------------------------------------------------------------------
// Styled components
// -----------------------------------------------------------------------------
const FooterContainer = styled.footer`
  flex: 0 0 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.footer.color};
  background: ${({ theme }) => theme.footer.background};
`;

// -----------------------------------------------------------------------------

const FooterContent = styled.div`
  flex: 2 1 0;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: "Amatic SC", cursive;
  font-size: 20px;
  font-weight: 400;

  svg {
    margin: 0;
    padding: 4px 4px 2px 4px;
    color: red;
    width: 14px !important;
    height: 14px !important;
  }

  span:first-child {
    width: 57px;
  }
  span:last-child {
    width: 89px;
  }
`;

// -----------------------------------------------------------------------------
// Render Component
// -----------------------------------------------------------------------------
export const Footer = (props) => (
  <FooterContainer>
    <Spacer>
      {props.left && props.left()}
    </Spacer>

    <FooterContent>
      <span>Made with</span>
      <FontAwesomeIcon icon="heart" />
      <span>for Boardgames</span>
    </FooterContent>

    <Spacer>
      {props.right && props.right()}
    </Spacer>
  </FooterContainer>
);

// -----------------------------------------------------------------------------

Footer.propTypes = {
  left: PropTypes.func,
  right: PropTypes.func
};
