import { createLocation, createBrowserHistory as createHistory } from "history";
import { observable, action, computed } from "mobx";

class LocationState {
  @observable
  location;

  // ---------------------------------------------------------------------------

  constructor() {
    this.history = createHistory();
    this.unlisten = this.history.listen(this.updateLocation);
    this.location = this.history.location;
  }

  // ---------------------------------------------------------------------------

  @action
  updateLocation = location => {
    this.location = location;
  };

  // ---------------------------------------------------------------------------

  @computed
  get language() {
    const match = this.location.pathname.match(/^\/(\w{2})/);
    return match ? match[1] : undefined;
  }

  // ---------------------------------------------------------------------------

  @computed
  get path() {
    return this.location.pathname;
  }

  // ---------------------------------------------------------------------------

  createHref = url => {
    const location = createLocation(url, null, null, this.location);
    return this.history.createHref(location);
  };

  // ---------------------------------------------------------------------------

  isActive = (url, prefixMatch = false) => {
    return (
      (prefixMatch && this.location.pathname.indexOf(url) > -1) ||
      this.location.pathname === url
    );
  };

  // ---------------------------------------------------------------------------

  goBack = () => {
    this.history.goBack();
  };

  // ---------------------------------------------------------------------------

  push = (location) => {
    this.history.push(location);
  };
}

export const Location = new LocationState();
